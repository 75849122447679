import axios from "axios";

export const DataService = {
    getDataByKey,
    postDatabyKey,
    putDatabyKey,
    deleteDatabyKey
};



function getDataByKey(key) {

    axios.defaults.headers = { 'Accept' :'application/json','Content-Type': 'application/json' ,'Authorization' : "Bearer "+ JSON.parse(localStorage.getItem('user')).token }
    
    return axios.get(''+key).then((res) => {return res})
}

function postDatabyKey(key,data)
{
    axios.defaults.headers = { 'Accept' :'application/json','Content-Type': 'application/json' ,'Authorization' : "Bearer "+ JSON.parse(localStorage.getItem('user')).token }
    return axios.post(key, data).then((res) => {return res}).catch(err => {
        if(err.response){
            return err.response;
        }
        else if(err.request)
          return  err.request
        else
        return  err.message;
    });
}


function putDatabyKey(key,data)
{
    axios.defaults.headers = { 'Accept' :'application/json','Content-Type': 'application/json' ,'Authorization' : "Bearer "+ JSON.parse(localStorage.getItem('user')).token }
    return axios.put(key, data).then((res) => {return res}).catch(err => {
        if(err.response){
            return err.response;
        }
        else if(err.request)
          return  err.request
        else
        return  err.message;
    })

}

function deleteDatabyKey(key)
{
    axios.defaults.headers = { 'Accept' :'application/json','Content-Type': 'application/json' ,'Authorization' : "Bearer "+ JSON.parse(localStorage.getItem('user')).token }
    return axios.delete(key).then((res) => {return res}).catch(error => {
        return error.response;
    })

}

//
// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 location.reload(true);
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         return data;
//     });
// }
