<script>
import Layout from "../layouts/main"
import PageHeader from "@/components/page-header";
import {
  GetDataMethods,
  PostDataMethods
} from "@/state/helpers";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Admin",
      items: [
        {
          text: "Wesal Units"
        },

      ],
      sortBy: "id",
      sortDesc: false,
      serviceData: [],
      data: {
        balance: 0,
      },
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "services_category.code", label: "Company Category" },
        { key: "name", label: "Name Service" },
        { key: "balance", label: "Balance" },
        { key: "updated_at", sortable: false, label: "Updated At" },
        // { key: "action" }
      ],
    }

  },

  props: ["id"],

  mounted() {
    this.getDataa()
  },

  methods: {
    ...GetDataMethods,
    ...PostDataMethods,

    getDataa() {
      this.getData("get_service_with_categories").then((res) => {
        this.serviceData = res.data.data.services;
      })
    },


    rechageBalance() {
      this.$refs["my-modal"].hide();
      this.postData({ key: "recharge_balance/" + this.id, data: this.data }).then((response) => {
        if (response.status == 422) {
          this.makeToast("danger ", "Fial", response.data.message);
        }
        else if (response.status == 500) {
          this.makeToast("danger ", "Fial", response.data.message);
        }
        else {
          this.makeToast("success ", "Success", response.data.message);
        }

      })
    },


    OnInput(id, val) {
      this.$refs["my-modal"].show();
      this.id = id;
      this.data.balance = val;
      return;
    },

    hendlerCancel() {
      this.$refs["my-modal"].hide();
      this.getDataa();
    },


    makeToast(variant = null, body = null, title = null) {
      this.$bvToast.toast(title, {
        title: `${body}`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Company Category</a>
                </template>

                <div class="table-responsive">
                  <b-table class="table-centered" :items="serviceData" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">

                    <template v-slot:cell(updated_at)="data">
                      <div>{{ data.value | formatDate }}</div>
                    </template>

                    <template v-slot:cell(has_prices)="data">
                      <div class="badge-soft-danger" v-if="data.value == 0"
                        style="border-radius: 15px; width: 60px;height: 25px;text-align: center;justify-content: center;align-items: center;display: flex">
                        False
                      </div>

                      <div class="badge-soft-success" v-else
                        style="border-radius: 15px; width: 60px;height: 25px;text-align: center;justify-content: center;align-items: center;display: flex;">
                        True</div>
                    </template>

                    <template v-slot:cell(balance)="data">
                      <div class="form-group">
                        <input v-model="data.value" v-on:keyup.enter="OnInput(data.item.id, data.value)" type="number"
                          min="0" class="form-control" />
                      </div>
                    </template>

                    <template v-slot:cell(action)>
                      <a type="submit" class="mr-3 text-primary" v-b-tooltip.hover>
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>


                <b-modal ref="my-modal" size="sm" hide-footer no-close-on-backdrop id="modal-backdrop" title="Edit"
                  title-class="font-18">
                  <p>Are you sure Update this Balance ?</p>
                  <b-button class="mt-2 mr-2" variant="outline-danger" @click="rechageBalance()">OK</b-button>
                  <b-button class="mt-2" variant="outline-primary" @click="hendlerCancel">Cancel</b-button>
                </b-modal>

              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>